// wall mounting enclosures
import w1 from "../assets/Wall Mounting Enclosures/1.jpg";
import w2 from "../assets/Wall Mounting Enclosures/2.jpg";
import w3 from "../assets/Wall Mounting Enclosures/3.jpg";
import w4 from "../assets/Wall Mounting Enclosures/4.jpg";
import w5 from "../assets/Wall Mounting Enclosures/5.jpg";

// Form4 Extendable Enclosure
import f1 from "../assets/Form4 Extendable Enclosure/1.jpg";
import f2 from "../assets/Form4 Extendable Enclosure/2.jpg";
import f3 from "../assets/Form4 Extendable Enclosure/3.jpg";

// Transformers
import t1 from "../assets/Transformer enclosure/1.jpg";
import t2 from "../assets/Transformer enclosure/2.jpg";
import t3 from "../assets/Transformer enclosure/3.jpg";

// Battery Cabinet
import b1 from "../assets/Battery Cabinet/1.jpg";
import b2 from "../assets/Battery Cabinet/2.jpg";
import b3 from "../assets/Battery Cabinet/3.jpg";

// Battery Rack
import br1 from "../assets/Battery Rack/1.jpg";
import br2 from "../assets/Battery Rack/2.jpg";
import br3 from "../assets/Battery Rack/3.jpg";

// Control Desk Enclosure
import c1 from "../assets/Control Desk Enclosure/1.jpg";
import c2 from "../assets/Control Desk Enclosure/2.jpg";
import c3 from "../assets/Control Desk Enclosure/3.jpg";

// Form 2 Extendable Enclosure
import fe1 from "../assets/Form 2 Extendable Enclosure/1.jpg";
import fe2 from "../assets/Form 2 Extendable Enclosure/2.jpg";
import fe3 from "../assets/Form 2 Extendable Enclosure/3.jpg";

// Monoblock Enclosure
import m1 from "../assets/Monoblock Enclosure/1.jpg";
import m2 from "../assets/Monoblock Enclosure/2.jpg";
import m3 from "../assets/Monoblock Enclosure/3.jpg";

// Server Rack and Cabinet
import s1 from "../assets/Server Rack and Cabinet/1.jpg";
import s2 from "../assets/Server Rack and Cabinet/2.jpg";
import s3 from "../assets/Server Rack and Cabinet/3.jpg";

// Aluminium Single or Double Wall Enclosure
import a1 from "../assets/Aluminum single and double wall/1.jpg";
import a2 from "../assets/Aluminum single and double wall/2.jpg";
import a3 from "../assets/Aluminum single and double wall/3.jpg";

// Stainless Steel Enclosure
import ss1 from "../assets/stainless steel encl/1.JPG";
import ss2 from "../assets/stainless steel encl/2.jpg";
import ss3 from "../assets/stainless steel encl/3.JPG";

// Package Substations
import p1 from "../assets/Pakage substation/1.JPG";
import p2 from "../assets/Pakage substation/2.JPG";
import p3 from "../assets/Pakage substation/3.jpg";

// Wall Rack Cabinet
import wrc1 from "../assets/wall rack  cabinet/1.JPG";
import wrc2 from "../assets/wall rack  cabinet/2.jpg";
import wrc3 from "../assets/wall rack  cabinet/3.jpg";

// Distribution Boards
import db1 from "../assets/distribution board/1.jpg";
import db2 from "../assets/distribution board/2.jpg";
import db3 from "../assets/distribution board/3.jpg";

// Push Button Enclosures
import pb1 from "../assets/push button encl/1.jpg";
import pb2 from "../assets/push button encl//2.jpg";
import pb3 from "../assets/push button encl/4.jpg";

const data = [
  {
    id: 1,
    slides: [
      { id: 1, image: w1, title: "Image 1" },
      { id: 2, image: w2, title: "Image 2" },
      { id: 3, image: w3, title: "Image 3" },
      { id: 4, image: w4, title: "Image 4" },
      { id: 5, image: w5, title: "Image 5" },
    ],
    title: "Wall Mounting Enclosures",
    materials: [
      {
        title: "Material",
        value: "Stainless Steel / Galvanized Steel / Aluminium",
      },
      {
        title: "Color",
        value: "Customized",
      },
      {
        title: "Mounting Plate",
        value: "Yes",
      },
      {
        title: "Number of doors",
        value: "1",
      },
      {
        title: "Protection Class (IP)",
        value: "Customized",
      },
      {
        title: "Viewing Door",
        value: "Customized",
      },
      {
        title: "Ventilation Door",
        value: "Customized",
      },
      {
        title: "Reardoor",
        value: "Customized",
      },
    ],
  },
  {
    id: 2,
    slides: [
      { id: 1, image: f1, title: "Image 1" },
      { id: 2, image: f2, title: "Image 2" },
      { id: 3, image: f3, title: "Image 3" },
    ],
    title: "Form4 Extendable Enclosure",
    description:
      "Our cubicles feature a modular construction available in various sizes, designed for flexibility and ease of use. They offer cable entry from the top or bottom, equipped with removable gland plates for added convenience. Internal segregation barriers and compartments are made from galvanized steel sheets, with eye bolts included for easy handling. Optional Type 7 cable boxes and plinths are also available. The cubicles are rated with an Ingress Protection of IP54 and are coated with electrostatic polyester powder for enhanced durability. We offer forms of internal separation ranging from Form 1 to Form 4 to meet diverse application needs.",
    materials: [
      {
        title: "Modular Construction",
        value: "Cubicles in different sizes",
      },
      {
        title: "Cable Entry",
        value: "Top or Bottom with removable gland plates",
      },
      {
        title: "Internal Segregation",
        value: "Barriers and compartments using GI steel sheets",
      },
      {
        title: "Handling",
        value: "Eye bolts for ease of handling",
      },
      {
        title: "Optional Items",
        value: "Type 7 Cable boxes and Plinth",
      },
      {
        title: "Ingress Protection",
        value: "IP54",
      },
      {
        title: "Coating",
        value: "Electrostatic Polyester Powder Coating",
      },
      {
        title: "Forms of Internal Separation",
        value: "Form 1 to Form 4",
      },
    ],
  },
  {
    title: "Transformers",
    id: 3,
    description:
      "We specialize in designing and manufacturing transformer enclosures that deliver optimal protection and efficiency for your valuable transformers. Our transformer enclosures are 100% customizable and offered at competitive prices, backed by our professional customer service team. With a wide range of options, they are ideal for safeguarding electrical components while ensuring powerful performance and ease of use. Built with robust construction, efficient ventilation, and noise reduction features, our enclosures prioritize safety, easy maintenance, and weather resistance. Each product is crafted with quality assurance in mind, providing long-lasting protection and efficiency for your transformers.",
    slides: [
      { id: 1, image: t1, title: "Image 1" },
      { id: 2, image: t2, title: "Image 2" },
      { id: 3, image: t3, title: "Image 3" },
    ],
    materials: [
      {
        title: "Transformer Enclosures",
        value:
          "100% customizable enclosures designed for optimal protection and efficiency of transformers.",
      },
      {
        title: "Competitive Pricing",
        value:
          "Affordable transformer enclosures backed by our professional customer service team.",
      },
      {
        title: "Wide Range",
        value:
          "A diverse selection of enclosures ideal for protecting electrical components.",
      },
      {
        title: "User-Friendly",
        value: "Powerful yet easy-to-use transformer enclosures.",
      },
      {
        title: "Robust Construction",
        value: "Durable enclosures built to withstand demanding conditions.",
      },
      {
        title: "Efficient Ventilation",
        value: "Designed with superior ventilation for optimal airflow.",
      },
      {
        title: "Noise Reduction",
        value:
          "Advanced noise reduction features to minimize operational sound.",
      },
      {
        title: "Safety",
        value: "Equipped with essential safety measures for secure operation.",
      },
      {
        title: "Easy Maintenance",
        value: "Engineered for convenient and hassle-free maintenance.",
      },
      {
        title: "Weather Resistance",
        value:
          "Weather-resistant enclosures built to perform in various environments.",
      },
      {
        title: "Quality Assurance",
        value:
          "Guaranteed quality for reliable performance and long-lasting protection.",
      },
    ],
  },
  {
    id: 4,
    slides: [
      { id: 1, image: b1, title: "Image 1" },
      { id: 2, image: b2, title: "Image 2" },
      { id: 3, image: b3, title: "Image 3" },
    ],
    title: "Battery Cabinet",
    description:
      "At Focus International, we design and manufacture battery cabinets and racks that offer secure, organized power solutions with precision engineering, robust construction, and high-quality materials to ensure safe storage, protection from damage, theft, and unauthorized access for a wide range of applications",
    materials: [
      {
        title: "Design",
        value: "Exquisite design with precise craftsmanship",
      },
      {
        title: "Frame",
        value:
          "Welded steel for front and back, flat packing, easy to transfer",
      },
      {
        title: "Doors",
        value: "High-density vented front and rear door with hexagonal holes",
      },
      {
        title: "Rotation",
        value: "Can turn over 180 degrees",
      },
      {
        title: "Side Panel",
        value: "Removable with latch, easy to install, optional lock",
      },
      {
        title: "Feet & Castors",
        value: "Adjustable feet and castors are available simultaneously",
      },
      {
        title: "Cable Entry",
        value: "Top entry, adjustable bottom entry with panel",
      },
      {
        title: "Locks",
        value: "Advanced handle locks for front door, optional other locks",
      },
      {
        title: "Mounting Profile",
        value: "S-shaped, free to move forward and back",
      },
      {
        title: "Baying Kit & Earthing Kit",
        value: "Efficient baying and earthing kits",
      },
      {
        title: "Plinth",
        value: "Optional plinth to fix cabinet on floor",
      },
      {
        title: "Under Base Cable Entry",
        value: "Available",
      },
      {
        title: "Optional Accessories",
        value:
          "Unassembled packing, 2/5 of original volume, saves container space",
      },
    ],
  },
  {
    id: 5,
    slides: [
      { id: 1, image: br1, title: "Image 1" },
      { id: 2, image: br2, title: "Image 2" },
      { id: 3, image: br3, title: "Image 3" },
    ],
    title: "Battery Rack",
    materials: [
      {
        title: "Modular System",
        value:
          "Exponential Power modular rack system can accommodate any battery cell or jar",
      },
      {
        title: "Compatibility",
        value:
          "Supports flooded to sealed, lead-acid to nickel-cadmium, vertical to horizontal mounting",
      },
      {
        title: "Design",
        value: "Designed for all types of stationary battery models",
      },
      {
        title: "Rack Features",
        value:
          "Strong, flexible, acid-proof, epoxy coated with rubberized coating on runners",
      },
      {
        title: "Installation",
        value: "Easily bolt together, making installation or alteration simple",
      },
      {
        title: "Spill Containment",
        value: "Allows for retrofit or removal of spill containment systems",
      },
      {
        title: "Modular Sections",
        value: "Built in modular sections, easily assembled and modified",
      },
    ],
  },
  {
    id: 6,
    slides: [
      { id: 1, image: c1, title: "Image 1" },
      { id: 2, image: c2, title: "Image 2" },
      { id: 3, image: c3, title: "Image 3" },
    ],
    title: "Control Desk Enclosure",
    description:
      "We specialize in designing and manufacturing control desk enclosures that provide efficient and ergonomic solutions for housing control systems and equipment. Built with precision and attention to detail, our control desk enclosures ensure optimal functionality and comfort for operators.",

    materials: [
      {
        title: "Design",
        value: "Free standing and sloped front design, easy to access",
      },
      {
        title: "Mounting Rails",
        value: "Adjustable mounting rails with simple alignment",
      },
      {
        title: "Mounting Options",
        value:
          "Can be mounted on either of two sides to provide a control surface with a 30° or 60° incline",
      },
      {
        title: "Usage",
        value: "Used to house electric or electronic components",
      },
      {
        title: "Control Device Mounting",
        value:
          "Ideal for mounting push buttons and other operator control devices",
      },
    ],
  },
  {
    id: 7,
    slides: [
      { id: 1, image: "https://placehold.co/600x400", title: "Image 1" },
      { id: 2, image: "https://placehold.co/600x400", title: "Image 2" },
      { id: 3, image: "https://placehold.co/600x400", title: "Image 3" },
    ],
    title: "Speakers and Boxes",
    materials: [
      {
        title: "Box Types",
        value:
          "Metal boxes available in every size and type, for indoor and outdoor applications",
      },
      {
        title: "Focus Series",
        value:
          "Complete range with a huge variety of models and sizes for varied applications",
      },
      {
        title: "Manufacturing",
        value:
          "Precision and quality ensure certified products and the highest construction standards",
      },
    ],
  },
  {
    id: 8,
    slides: [
      { id: 1, image: fe1, title: "Image 1" },
      { id: 2, image: fe2, title: "Image 2" },
      { id: 3, image: fe3, title: "Image 3" },
    ],
    title: "Form 2 Extendable Enclosure",
    description:
      "Introducing our innovative line of extendable enclosures at Focus, designed to offer flexibility and scalability for your evolving needs. These modular enclosures are perfect for heavy-duty applications, providing a versatile solution that grows with your equipment. With flexible sizing, expansion options, and efficient space utilization, our enclosures ensure maximum adaptability. Their durable construction guarantees long-lasting performance, while easy installation and customization options make them the ideal choice for a variety of environments.",
    materials: [
      {
        title: "Protection Class",
        value: "Up to IP65",
      },
      {
        title: "Coating",
        value: "Powder coated",
      },
      {
        title: "Hinges",
        value: "110° door opening",
      },
      {
        title: "Doors",
        value: "Reversible for left and right opening",
      },
      {
        title: "Mounting Plate",
        value: "Adjustable with superior studs",
      },
      {
        title: "Gland Plates",
        value: "Removable",
      },
      {
        title: "Cable Entry",
        value: "Top and bottom with gaskets",
      },
      {
        title: "Lock",
        value: "Quarter turn",
      },
    ],
  },
  {
    id: 9,
    slides: [
      { id: 1, image: m1, title: "Image 1" },
      { id: 2, image: m2, title: "Image 2" },
      { id: 3, image: m3, title: "Image 3" },
    ],
    title: "Monoblock Enclosure",
    materials: [
      {
        title: "Frame",
        value: "Welded frame for applications on the machine",
      },
      {
        title: "Performance",
        value: "Guarantees reliability and high performance",
      },
      {
        title: "Range",
        value: "Wide range of sizes to meet current market needs",
      },
      {
        title: "Product Series",
        value: "One-piece frame with removable back or fully welded frame",
      },
    ],
  },
  {
    id: 10,
    slides: [
      { id: 1, image: ss1, title: "Image 1" },
      { id: 2, image: ss2, title: "Image 2" },
      { id: 3, image: ss3, title: "Image 3" },
    ],
    title: "Stainless Steel Enclosure",
    description:
      "Our IP66 stainless steel enclosures are expertly manufactured using either 304 or 316L grade stainless steel, with a thickness of up to 3mm. These enclosures can be finished in either a brush or highly polished mirror finish, catering to various aesthetic and functional requirements. Ideal for highly corrosive environments, they are perfectly suited for industries such as healthcare, oil and gas, chemical, and food. Available in any size to meet specific application needs, our stainless steel enclosures provide robust protection and durability for critical equipment",
    materials: [
      {
        title: "Grade",
        value: "304 or 316L grade stainless steel",
      },
      {
        title: "Thickness",
        value: "Up to 3mm",
      },
      {
        title: "Finish",
        value: "Available in brush or highly polished mirror finish",
      },
      {
        title: "Industry Applications",
        value:
          "Best suited for Health care, Oil and Gas, Chemical, and Food industries",
      },
      {
        title: "Environment",
        value: "Designed for highly corrosive environments",
      },
      {
        title: "Customization",
        value:
          "Can be manufactured in any size depending on application and need",
      },
    ],
  },
  {
    id: 11,
    slides: [
      { id: 1, image: a1, title: "Image 1" },
      { id: 2, image: a2, title: "Image 2" },
      { id: 3, image: a3, title: "Image 3" },
    ],
    title: "Aluminium Single or Double Wall Enclosure",
    description:
      "Completely designed for housing low voltage protection devices, street lighting, or other outdoor applications, these enclosures are made of Al-Mg sheet aluminum with single or double walls, protected by a polyester powder coating and featuring top and bottom ventilation",
    materials: [
      {
        title: "Design",
        value: "Designed for housing and protection of low voltage devices",
      },
      {
        title: "Applications",
        value: "Suitable for street lighting or any other outdoor application",
      },
      {
        title: "Material",
        value: "Made of AL MG aluminum",
      },
      {
        title: "Wall Type",
        value: "Single or double wall",
      },
      {
        title: "Protection",
        value: "Coated with polyester powder paint",
      },
      {
        title: "Ventilation",
        value: "Top and bottom ventilation",
      },
    ],
  },
  {
    id: 12,
    slides: [
      { id: 1, image: "https://picsum.photos/200/400", title: "Image 1" },
      { id: 2, image: "https://picsum.photos/200/401", title: "Image 2" },
      { id: 3, image: "https://picsum.photos/200/402", title: "Image 3" },
    ],
    title: "Floor Standing Enclosure",
    description:
      "Our extendable enclosures are available in both floor-standing and wall-mounting options, including the Form 2 Extentable Enclosure, offering protection up to IP65. Designed for convenience and durability, these enclosures feature a powder-coated finish, 110° door opening hinges, and reversible doors for left or right-side access. The adjustable mounting plate with superior studs ensures flexibility, while removable gland plates and top and bottom cable entry with gasket provide seamless cable management. For added security, a quarter-turn lock is included to protect your equipment",
    materials: [
      {
        title: "Material",
        value: "Stainless steel / Galvanized steel / Aluminium",
      },
      {
        title: "Colour",
        value: "Customized",
      },
      {
        title: "Mounting Plate",
        value: "Yes",
      },
      {
        title: "Number of Doors",
        value: "1",
      },
      {
        title: "Protection Class (IP)",
        value: "Customized",
      },
      {
        title: "Viewing Door",
        value: "Customized",
      },
      {
        title: "Ventilation Door",
        value: "Customized",
      },
      {
        title: "Rear Door",
        value: "Customized",
      },
    ],
  },
  {
    id: 13,
    slides: [
      { id: 1, image: p1, title: "Image 1" },
      { id: 2, image: p2, title: "Image 2" },
      { id: 3, image: p3, title: "Image 3" },
    ],
    title: "Package Substations",
    materials: [
      {
        title: "Design",
        value:
          "Flexible designs to accommodate various RMU and feeder pillar positions",
      },
      {
        title: "Weatherproof",
        value: "Suited for both indoor and outdoor locations",
      },
      {
        title: "Standard Ratings",
        value:
          "500, 1000, 1500 kVA, with other ratings available up to 3000 kVA",
      },
      {
        title: "Benefits",
        value: "Reductions in cost, footprint, and cable run lengths",
      },
    ],
  },
  {
    id: 14,
    slides: [
      { id: 1, image: s1, title: "Image 1" },
      { id: 2, image: s2, title: "Image 2" },
      { id: 3, image: s3, title: "Image 3" },
    ],
    title: "Server Rack and Cabinet",
    description:
      "A server rack houses and organizes critical IT systems and can be configured to support a wide range of requirements. Often referred to as a server rack cabinet, it is enclosed to ensure security. While server racks are most commonly found in data center environments, they can also be used in smaller computer closets",

    materials: [
      {
        title: "Panels and Doors",
        value:
          "Removable panels and doors to facilitate equipment installation",
      },
      {
        title: "Front Door",
        value:
          "Reversible glass front door with quick release hinges and key lock handle",
      },
      {
        title: "Rear Door",
        value: "Reversible metal rear door with handle and key lock",
      },
      {
        title: "Side Panels",
        value: "Blind side panels, removable with key locks",
      },
      {
        title: "Roof",
        value:
          "Special pagoda roof installed 10mm from the cabinet structure for passive ventilation, or active ventilation with installed ventilation groups; profile with brush for cable entry",
      },
      {
        title: "Bottom",
        value: "Designed for cable entry through pre-cut profiles",
      },
      {
        title: "Feet",
        value: "Complete with adjustable feet",
      },
    ],
  },
  {
    id: 15,
    slides: [
      { id: 1, image: wrc1, title: "Image 1" },
      { id: 2, image: wrc2, title: "Image 2" },
      { id: 3, image: wrc3, title: "Image 3" },
    ],
    title: "Wall Rack Cabinet",
    description:
      " This rack cabinet is designed for storing rack-mounted equipment for various applications, including Networking and Communications, DVRs and Security, PBX/PABX Telephone systems, or any other equipment requiring rack-mounted panels and switches",
    materials: [
      {
        title: "Construction",
        value: "Built-in closet individual section 6 units supplied assembled",
      },
      {
        title: "Material",
        value: "Solid welded cold-rolled steel",
      },
      {
        title: "Front Door",
        value:
          "Tempered glass 5 mm, easily removable and reversible, opening angle greater than 180°, with key lock",
      },
      {
        title: "Side Panels",
        value: "Blinds fixed, non-removable",
      },
      {
        title: "Cable Entry",
        value:
          "Equipped with cable entry on the base and roof profiles via knockouts",
      },
      {
        title: "Fan Preparation",
        value:
          "Preparation for installation of two 120 mm fans on the roof and the base",
      },
      {
        title: "Included",
        value: "Includes kit of screws, nuts, and key for installation",
      },
    ],
  },
  {
    id: 16,
    slides: [
      { id: 1, image: db1, title: "Image 1" },
      { id: 2, image: db2, title: "Image 2" },
      { id: 3, image: db3, title: "Image 3" },
    ],
    title: "Distribution Boards",
    materials: [
      {
        title: "Design",
        value:
          "Compact Final Distribution Boards (FDB) designed for safety, reliability, and availability in electrical building installations",
      },
      {
        title: "Types",
        value: "Available in busbar and multi-row types",
      },
      {
        title: "Mounting",
        value: "Surface or flush mounting on request and requirements",
      },
    ],
  },
  {
    id: 17,
    slides: [
      { id: 1, image: pb1, title: "Image 1" },
      { id: 2, image: pb2, title: "Image 2" },
      { id: 3, image: pb3, title: "Image 3" },
    ],
    title: "Push Button Enclosures",
    materials: [
      {
        title: "Applications",
        value:
          "Used in controlling motorized equipment, elevators, conveyor systems, and more",
      },
      {
        title: "Purpose",
        value:
          "Protects control components from damage and ensures safe and reliable operation of the equipment",
      },
      {
        title: "Importance",
        value:
          "Essential component of any industrial or commercial control system",
      },
    ],
  },
  {
    id: 18,
    slides: [
      { id: 1, image: "https://placehold.co/600x400", title: "Image 1" },
      { id: 2, image: "https://placehold.co/600x400", title: "Image 2" },
      { id: 3, image: "https://placehold.co/600x400", title: "Image 3" },
    ],
    title: "Sloped Top Enclosure",
    materials: [
      {
        title: "Purpose",
        value: "Ideal for above-ground protection of electrical components",
      },
      {
        title: "Design Feature",
        value:
          "Sloped top to prevent contaminants from collecting on the surface",
      },
      {
        title: "Protection",
        value:
          "Protects against spraying, splashing, and seepage of water, oil, and non-corrosive coolants",
      },
    ],
  },
];

const getData = (id) => {
  return data.find((item) => item.id === parseInt(id, 10));
};

export default getData;
